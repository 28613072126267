/* eslint-disable @typescript-eslint/no-unused-vars */
import DefaultLayout from "@/wireframes/layouts/default";
import ContactUsHeaderSection from "./contact-us/contact-us-headerSection";
import Map from "@/components/Map";
import ContactUsForm from "@/page-sections/contact-us/contact-us-form";
import Header from "@/components/header/Header";
import Footer from "@/components/header/Footer";

const ContactUsPage: React.FC = () => {
  return (
    <>
      <DefaultLayout header={<Header />} footer={<Footer />}>
        <div className="section-colors">
          <ContactUsHeaderSection />
        </div>
        <div className="section-colors">
          <ContactUsForm />
        </div>
        <div className="section-colors">
          {/* <SupportPage /> */}
          <Map />
        </div>
        {/* <div className="section-colors">
          <GetInTouchPage />
        </div> */}
      </DefaultLayout>
    </>
  );
};

export default ContactUsPage;
