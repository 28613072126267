/* eslint-disable @typescript-eslint/no-unused-vars */
import { cn } from "@/lib/utils";
import DotPattern from "@/wireframes/backgrounds/pattern/dot-pattern";
import HeroSectionWithProps from "@/wireframes/page-sections/hero/HeroSectionWithProps";
import { useTheme } from "@/wireframes/theme/theme-provider";

const ContactUsHeaderSection: React.FC = () => {
  const { theme } = useTheme();

  const marqueeList = [
    {
      id: "1",
      name: "farm_rent",
      imgLight: "/clients-logo/light-logo/farmrent-light.webp",
      imgDark: "/clients-logo/dark-logo/farmrent-dark.webp"
    },
    {
      id: "2",
      name: "amgaon_hospital",
      imgLight: "/clients-logo/light-logo/amgaon-hospital-light.webp",
      imgDark: "/clients-logo/light-logo/amgaon-hospital-light.webp"
    },
    {
      id: "3",
      name: "brain_teasers",
      imgLight: "/clients-logo/light-logo/brain-teasers-light.webp",
      imgDark: "/clients-logo/light-logo/brain-teasers-light.webp"
    },
    {
      id: "4",
      name: "farm_rent",
      imgLight: "/clients-logo/light-logo/farmrent-light.webp",
      imgDark: "/clients-logo/dark-logo/farmrent-dark.webp",
    },
    {
      id: "5",
      name: "amgaon_hospital",
      imgLight: "/clients-logo/light-logo/amgaon-hospital-light.webp",
      imgDark: "/clients-logo/light-logo/amgaon-hospital-light.webp",
    },
    {
      id: "6",
      name: "brain_teasers",
      imgLight: "/clients-logo/light-logo/brain-teasers-light.webp",
      imgDark: "/clients-logo/light-logo/brain-teasers-light.webp",
    },
    {
      id: "7",
      name: "dd_grocers",
      imgLight: "/clients-logo/light-logo/dd-grocers-light.webp",
      imgDark: "/clients-logo/dark-logo/dd-grocers-dark.webp",
    },
    {
      id: "8",
      name: "efw",
      imgLight: "/clients-logo/light-logo/efw-light.webp",
      imgDark: "/clients-logo/dark-logo/efw-dark.webp",
    },
    {
      id: "9",
      name: "eventneve",
      imgLight: "/clients-logo/light-logo/eventneve-light.webp",
      imgDark: "/clients-logo/light-logo/eventneve-light.webp",
    },
    {
      id: "10",
      name: "flowers_kart",
      imgLight: "/clients-logo/light-logo/flowers-kart-light.webp",
      imgDark: "/clients-logo/light-logo/flowers-kart-light.webp",
    },
    {
      id: "11",
      name: "garbh_sanskar",
      imgLight: "/clients-logo/light-logo/garbh-sanskar-light.webp",
      imgDark: "/clients-logo/light-logo/garbh-sanskar-light.webp",
    },
    {
      id: "12",
      name: "intoxica",
      imgLight: "/clients-logo/light-logo/intoxica-light.webp",
      imgDark: "/clients-logo/dark-logo/intoxica-dark.webp",
    },
    {
      id: "13",
      name: "life_belt",
      imgLight: "/clients-logo/light-logo/lifebelt-corporation-light.webp",
      imgDark: "/clients-logo/light-logo/lifebelt-corporation-light.webp",
    },
    {
      id: "14",
      name: "pooches_world",
      imgLight: "/clients-logo/light-logo/pooches-world-light.webp",
      imgDark: "/clients-logo/dark-logo/pooches-world-dark.webp",
    },
    {
      id: "15",
      name: "star_india",
      imgLight: "/clients-logo/dark-logo/star-india-dark.webp",
      imgDark: "/clients-logo/dark-logo/star-india-dark.webp",
    },
    {
      id: "16",
      name: "tj_suspension",
      imgLight: "/clients-logo/light-logo/tj-suspension-light.webp",
      imgDark: "/clients-logo/light-logo/tj-suspension-light.webp",
    },
    {
      id: "17",
      name: "vedyug",
      imgLight: "/clients-logo/light-logo/vedyug-light.webp",
      imgDark: "/clients-logo/light-logo/vedyug-light.webp",
    },
    {
      id: "18",
      name: "wireframes",
      imgLight: "/clients-logo/light-logo/wireframes-light.webp",
      imgDark: "/clients-logo/light-logo/wireframes-light.webp",
    },
    {
      id: "19",
      name: "carohbar",
      imgLight: "/clients-logo/light-logo/carohbar-light.webp",
      imgDark: "/clients-logo/dark-logo/carohbar-dark.webp",
    },
  ]


  const PatternComponent = () => (
    <DotPattern
      width={20}
      height={20}
      cx={1}
      cy={1}
      cr={1}
      className="[mask-image:linear-gradient(to_bottom_right,white,transparent,transparent)]"
    />
  );
  const MarqueeCardComponent = ({
    imgLight,
    imgDark,
  }: {
    imgLight: string;
    imgDark: string;
  }) => {
    const logo = theme === "dark" ? imgLight : imgDark;
    return (
      <div
        className={cn(
          "relative cursor-pointer overflow-hidden rounded-xl transition duration-300",
          "w-full md:w-1/2 lg:w-1/3 xl:w-1/4 px-2 mb-4"
        )}
      >
        <div className="w-full h-20 md:h-12 lg:h-14 xl:h-20 flex justify-center items-center">
          <img
            className="w-full h-full object-contain filter grayscale hover:grayscale-0 transition duration-300"
            alt="company"
            src={logo}
          />
        </div>
      </div>
    );
  };
  return (
    <HeroSectionWithProps
      id="hero"
      title="Contact Us"
      description="Discover unparalleled growth opportunities with Kaliba Enterprises. Become a part of our dynamic, tech-driven team poised to shape the digital landscape. We invite ambitious minds to collaborate, innovate, and prosper together as we craft the future. Join us in shaping tomorrow's technology today."
      marqueeList={marqueeList ? marqueeList : []}
      MarqueeCardComponent={MarqueeCardComponent}
      PatternComponent={PatternComponent}
    />
  );
};

export default ContactUsHeaderSection;
