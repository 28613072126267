import React from "react";
import DefaultLayout from "@/wireframes/layouts/default";
import Header from "@/components/header/Header";
import Footer from "@/components/header/Footer";
import ServiceDetails from "@/page-sections/services/service-details";
import GetInTouchPage from "@/page-sections/contact-us/get-in-touch-page";

const ServicePage: React.FC = () => {
  return (
    <>
      <DefaultLayout header={<Header />} footer={<Footer />}>
        <div className="section-colors">
          <ServiceDetails/>
        </div>
        <div className="section-colors">
          <GetInTouchPage/>
        </div>
      </DefaultLayout>
    </>
  );
};

export default ServicePage;
