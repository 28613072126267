/* eslint-disable @typescript-eslint/no-unused-vars */
// src/pages/AboutPage.tsx
import React from "react";
import DefaultLayout from "@/wireframes/layouts/default";
import GetInTouchPage from "@/page-sections/contact-us/get-in-touch-page";
import AboutUsHeaderSection from "@/page-sections/about-us/about-us-headerSection";
import Header from "@/components/header/Header";
import Footer from "@/components/header/Footer";

const AboutUsPage: React.FC = () => {
  return (
    <>
      <DefaultLayout header={<Header />} footer={<Footer />}>
        <div className="section-colors">
          <AboutUsHeaderSection />
        </div>
        <div className="section-colors">
          <GetInTouchPage />
        </div>
      </DefaultLayout>
    </>
  );
};

export default AboutUsPage;
